<template>
    <div
        class="menu-dropdown__balance"
        :class="{'menu-dropdown__balance--soon': +daysTo <= 7}"
    >
        <div class="balance__info">
            <span class="balance__current">Баланс: {{ balanceAndPayment.balance }} ₽</span>
            <span v-if="balanceAndPayment.date_to && !balanceAndPayment.is_lock" class="balance__remains">закончится {{dateRemains}}</span>
            <span v-else-if="balanceAndPayment.is_lock" class="balance__remains">Портал заблокирован за неуплату</span>
        </div>
        <router-link to="/billing/balance_replenishment/" class="v-btn sidebar__btn sidebar__btn--balance">Пополнить</router-link>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'Balance',
        data() {
            return {
                date: null
            };
        },
        computed: {
            dateRemains() {
                return this.$moment(this.balanceAndPayment.date_to).from(this.date);
            },

            daysTo() {
                return this.$moment(this.balanceAndPayment.date_to).diff(new Date(), 'days');
            },

            name() {
                return this.data;
            },

            ...mapGetters('settings', ['balanceAndPayment']),
            ...mapState('default_data', ['time'])
        },

        created() {
            this.date = new Date().toLocaleString('en-US', { timeZone: this.time.time_zone, hour12: false }).replace(',', '');
        }
    };
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .menu-dropdown {
        &__balance {
            padding: 15px 25px;
            background-color: var(--balance-bg);
            &--soon {
                background: var(--balance-soon-bg);
                .sidebar__btn {
                    &:hover {
                        background: #FFF !important;
                        @media (max-width: 1000px) {
                            background: var(--sidebar-btn-border) !important;
                        }
                    }
                }
            }
            .balance {
                &__info {
                    display: flex;
                    flex-direction: column;
                    color: var(--balance-text-color);
                }
                &__current {
                    font-weight: 600;
                    font-size: 1rem;
                    color: var(--balance-text-color);
                }
            }
        }
    }
</style>
